import { useState, useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { EventBus, HttpClient } from "@gearcode/react-components/lib/Services";
import DashboardContext from "contexts/DashboardContext";
import { LinearProgress, Typography } from "@mui/material";
import LogoView from "./LogoView";
import { UNAUTHORIZED } from "../../events";
import { USER_ACCOUNTS_LOADED } from "store/actions";

const UserAccountsLoader = ({ children }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const dashboardContext = useContext(DashboardContext);
  const { t } = useTranslation(["GcDashboard-UserAccountsLoader"]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function getUserAccounts() {
      const response = await HttpClient.send(
        {
          url: `${dashboardContext.apiBaseUrl}/auth/accounts`,
        },
        { stopErrorDisptaching: true }
      );

      if (response.succeed) {
        const userAccounts = await response.json();

        if (!userAccounts.length) {
          EventBus.dispatch(UNAUTHORIZED);
          setIsLoading(false);
          return;
        }

        dispatch({ type: USER_ACCOUNTS_LOADED, userAccounts });
        setIsLoading(false);
      }
    }
    getUserAccounts();
  }, [dashboardContext.apiBaseUrl]);

  const loader = (
    <LogoView>
      <Typography
        color={theme.palette.secondary.main}
        gutterBottom
        variant="h3"
        textAlign="center"
      >
        {t("Loading", "Loading")}...
      </Typography>
      <LinearProgress sx={{ width: "100%" }}></LinearProgress>
    </LogoView>
  );

  return <>{isLoading ? loader : children}</>;
};

export default UserAccountsLoader;
