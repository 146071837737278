import { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { HttpClient, EventBus } from "@gearcode/react-components/lib/Services";
import DashboardContext from "contexts/DashboardContext";
import PersonIcon from "@mui/icons-material/Person";
import {
  Dialog,
  DialogContent,
  LinearProgress,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { CURRENT_USER_CONTEXT_CHANGED } from "store/actions";
import {
  CURRENT_SCOPE_CHANGED,
  CURRENT_USER_ACCOUNT_CHANGED,
} from "../../events";
import {
  CookieManager,
  COOKIENAME_CURRENTUSERACCOUNTKEY,
} from "components/common/CookieManager";

const UserContextSwitcher = () => {
  const USER_CONTEXT_INFO_STORAGE_KEY = "gc.dashboard.usercontextinfo";

  const theme = useTheme();
  const dispatch = useDispatch();
  const dashboardContext = useContext(DashboardContext);
  const { t } = useTranslation(["GcDashboard-UserAccountsLoader"]);

  const [isLoading, setIsLoading] = useState(false);
  const userAccounts = useSelector((state) => state.userAccounts.userAccounts);
  const currentUserContext = useSelector(
    (state) => state.userAccounts.currentUserContext
  );

  const setRequestsParams = (userAccount, scope) => {
    // sets new account key cookie to be send with an every request to API
    CookieManager.setCookie(
      COOKIENAME_CURRENTUSERACCOUNTKEY,
      userAccount.accountKey
    );

    HttpClient.defaultHeaders["scope"] = scope || "";
  };

  const saveUserContextInfo = (userContext) => {
    CookieManager.setCookie(
      COOKIENAME_CURRENTUSERACCOUNTKEY,
      userContext.account.accountKey
    );

    localStorage.setItem(
      USER_CONTEXT_INFO_STORAGE_KEY,
      JSON.stringify({
        scope: userContext.scope,
      })
    );
  };

  const readUserContextInfo = () => {
    const accountKey = CookieManager.getCookie(
      COOKIENAME_CURRENTUSERACCOUNTKEY
    );
    const localStorageData =
      JSON.parse(localStorage.getItem(USER_CONTEXT_INFO_STORAGE_KEY)) || {};

    return { accountKey: accountKey, scope: localStorageData.scope };
  };

  const notifyUserContextChanged = (userContext) => {
    saveUserContextInfo(userContext);

    dispatch({
      type: CURRENT_USER_CONTEXT_CHANGED,
      currentUserContext: userContext,
    });
  };

  const loadFeatures = async () => {
    var timeout = setTimeout(() => {
      setIsLoading(true);
    }, 500);

    try {
      const response = await HttpClient.send({
        url: `${dashboardContext.apiBaseUrl}/features`,
      });

      const features = await response.json();
      clearTimeout(timeout);

      return features;
    } finally {
      clearTimeout(timeout);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    async function setInitialUserContext() {
      const userContext = { account: {}, scope: "", features: [] };

      const savedUserContextInfo = readUserContextInfo();
      const currentUserAccount = userAccounts.find(
        (a) => a.accountKey === savedUserContextInfo.accountKey
      );

      if (currentUserAccount) {
        userContext.account = currentUserAccount;
        userContext.scope = currentUserAccount.scopes.find(
          (s) => s === savedUserContextInfo.scope
        );
      } else {
        userContext.account = userAccounts[0];
      }

      setRequestsParams(userContext.account, userContext.scope);

      const features = await loadFeatures();
      userContext.features = features;

      notifyUserContextChanged(userContext);
    }
    setInitialUserContext();
  }, [userAccounts]);

  useEffect(() => {
    const currentUserAccountChangedHandler = async (account) => {
      let isAccountSelected = account ? true : false;
      if (!isAccountSelected || isLoading) {
        return;
      }

      // resets scope due to user account changed
      const scope = "";
      setRequestsParams(account, scope);

      var features = await loadFeatures();

      const userContext = {
        account: account,
        scope: scope,
        features: features,
      };

      notifyUserContextChanged(userContext);
    };

    const currentScopeChangedHandler = (scope) => {
      const userContext = {
        account: currentUserContext.account,
        scope: scope,
        features: currentUserContext.features,
      };

      setRequestsParams(userContext.account, userContext.scope);
      notifyUserContextChanged(userContext);
    };

    EventBus.on(CURRENT_USER_ACCOUNT_CHANGED, currentUserAccountChangedHandler);
    EventBus.on(CURRENT_SCOPE_CHANGED, currentScopeChangedHandler);

    return () => {
      EventBus.remove(
        CURRENT_USER_ACCOUNT_CHANGED,
        currentUserAccountChangedHandler
      );
      EventBus.remove(CURRENT_SCOPE_CHANGED, currentScopeChangedHandler);
    };
  }, [currentUserContext]);

  const loader = (
    <Dialog
      open={isLoading}
      sx={{
        backdropFilter: "blur(2px)",
      }}
    >
      <DialogContent>
        <Stack alignItems="center" spacing={2}>
          <PersonIcon
            fontSize="large"
            sx={{ color: theme.palette.secondary.main }}
          ></PersonIcon>
          <Typography gutterBottom variant="h3" textAlign="center">
            {t("SwitchingUserContext", "Switching user context")}
          </Typography>
          <LinearProgress sx={{ width: "100%" }}></LinearProgress>
        </Stack>
      </DialogContent>
    </Dialog>
  );

  return loader;
};

export default UserContextSwitcher;
