import { lazy } from "react";
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import { Navigate } from "react-router";

const DashboardDefault = Loadable(
  lazy(() => import("components/dashboard/Default"))
);

const AzureSearchLoaderLogs = Loadable(
  lazy(() => import("components/asl/index"))
);

const FeedLogs = Loadable(
  lazy(() => import("components/feed/index"))
);

const ImportDataLogs = Loadable(
  lazy(() => import("components/dataSyncLogs/DataLogs"))
);

const ImportADFLogs = Loadable(
    lazy(() => import("components/dataSyncLogs/DataFactoryLogs"))
);

const ScoringProfile = Loadable(
  lazy(() => import("components/scoringProfile/index"))
);

const CalendarEvents = Loadable(
  lazy(() => import("components/calendar/index"))
);

const OtherRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/default",
      element: <DashboardDefault />,
    },
    {
      path: "/asl",
      element: <AzureSearchLoaderLogs />,
    },
    {
      path: "/feed",
      element: <FeedLogs />,
    },
    {
      path: "/calendar-events",
      element: <CalendarEvents />,
    },
    {
      path: "/scoring-profile",
      element: <ScoringProfile />,
    },    
    {
      index: true,
      element: <Navigate to="default" />,
    },
    {
      path: "/datasync/data",
      element: <ImportDataLogs />,
    },
    {
      path: "/datasync/datafactory",
      element: <ImportADFLogs />,
    },
  ],
};

export default OtherRoutes;
